<template>
	<div id="backIframeNode" class="w-full h-full">
		<div v-if="layout.error" class="flex flex-col items-center text-center pt-32">
			<img :src="require('@/assets/images/rosepetal/icon/errorNet.png')" class="w-32" />
			<div class="mt-5 text-gray-600">{{ $t('Controller is offline') }}</div>
			<button class="btn flex items-center bg-gray-100 border-gray-300 py-1 px-8 mt-3 text-sm font-normal hover:bg-blue-900 hover:text-white" @click="forceReload()">{{ $t('Retry') }}</button>
		</div>
		<div v-else class="flex flex-col items-center text-center"><img :src="require('@/assets/images/rosepetal/icon/train7.gif')" class="w-72" /></div>
	</div>
</template>

<script>
import { defineComponent, onMounted, ref, computed,  onUnmounted, watch } from "vue";
import { useStore } 		from "@/store";
import cash 				from "cash-dom";

import * as rosepetalModel  from "rosepetal-model";

let $controller = rosepetalModel.controller;

export default defineComponent({
	setup() {
		const store            = useStore();
		const layout           = ref({ error: false });
		const User             = computed(() => store.state.main.User);
		const userData         = computed(() => store.state.main.userData);
		
		const loadNR = async (url) => {  
			layout.value.error  = false;
			const uiFrame       = document.getElementById("uiFrame");
			uiFrame.src         = "about:blank";

			let host = $controller.host;
			if ($controller.zerotierEnabled) host = $controller.zerotier;

			uiFrame.onload = async () => {
				if (uiFrame.src === "about:blank") return;

				let _status = await $controller.getStatus(host);

				if (_status.error) {
					cash(".content").removeClass("hidden");
					cash("#topBarUiIframe").css("display", "none");
					uiFrame.style.display	= 'none';
					layout.value.error 		= true;
					uiFrame.src 			= "about:blank";  
				} else {
					cash(".content,#backIframeNode").addClass("hidden");
					uiFrame.style.display = 'block';
				}
			};

			uiFrame.src = host + "/nodered" + url + "?uid=" + User.value.uid;
			console.log("NR UiFrame:", uiFrame.src);

			/* if (User.value.uid) {

				uiFrame.src = nodeConfig.host + "/nodered" + url + "?uid=" + User.value.uid;

				console.log("UiFrame:", uiFrame.src);

				let UserRole = await $user.getRole(User.value.email);
				if (UserRole && UserRole?.role_id)uiFrame.src +=  "&role_id=" + UserRole?.role_id;
				
				console.log('Role', UserRole);
			} */
		}

		const forceReload = async () => { await loadNR('/ui'); }

		onMounted( async () => { 
			cash(".content").addClass("rpContentNodeBackground");
			cash("#topBarUiIframe").css("display", "block");
			cash("body").css("overflow-y", "hidden");
			cash(".dashboxItem,.side-nav").addClass("hidden");
			cash(".top-bar-node-menu").removeClass("hidden");
			cash(".top-bar-node-menu-side-menu-node").addClass("top-bar-node-menu-current");
			
			if (cash("#uiFrame").attr("src") == "about:blank") {
				await loadNR('/ui/');
			} else {
				layout.value.error = false;
				cash("#topBarControllerIframe").css("display", "block");
				cash(".content").addClass("hidden");
				document.getElementById("uiFrame").style.display = 'block';
			}
		});

		onUnmounted( () => { 
			cash("#uiFrame").attr("src", "about:blank");
			cash(".content").removeClass("rpContentNodeBackground");
			cash(".dashboxItem,.side-nav,.content").removeClass("hidden");
			cash(".top-bar-node-menu").addClass("hidden");
			cash("#topBarUiIframe").css("display", "none");
			cash(".top-bar-node-menu-current").removeClass("top-bar-node-menu-current");
			cash("body").css("overflow-y", "scroll");
		});

		watch(User, (newValue) => {
			if (newValue && newValue.uid) {
				loadNR('/ui/');
			}
		});

		return{
			layout,
			forceReload,
			userData,
			User
		}
	}
});
</script>

<style>
.rpContentNodeBackground{ background-color: #fff !important; padding: 0 !important; }
</style>
<template>
	<router-view />
</template>

<script>
import { useRouter, useRoute }	from "vue-router";
import { user as $user } 		from "@/model/user";
import { onBeforeMount } 		from "vue";
import { useStore } 			from "@/store";

import * as rosepetalModel 		from "rosepetal-model";
import config 					from './etc/rosepetal.json';

let $_firebase 	= rosepetalModel._firebase;
let $aws		= rosepetalModel.aws;
let $controller	= rosepetalModel.controller;
let $event		= rosepetalModel.event;

let url			= new URL(window.location.href);
let zerotierQry	= url.search.includes("?zerotier");

config.nodeConfig.host = url.origin;

$controller.setHost(config.nodeConfig.host);
$controller.setZerotier(config.zerotier ? config.zerotier : false);

if (zerotierQry && config.zerotier) {
    url.searchParams.delete('zerotier');
    window.history.replaceState({}, '', url.toString());
	$controller.zerotierEnabled = true;
}

$_firebase.initializeApp(config.firebaseConfig);
$_firebase.setHost(config);

export default {
	setup() {
		const router	= useRouter();
		const route 	= useRoute();
		const store 	= useStore();

		onBeforeMount(async () => {
			let auth = $_firebase.auth();
			auth.onAuthStateChanged(async (user) => {
				if (user) {
					user.role = await $user.getRole(user.email);
					const aws = await $aws.initAws();

					console.log("User:", user.role);
					console.log(aws);
				}

				if (!user) {
					sessionStorage.setItem('redirect', route.fullPath);
					router.replace("/login");
				}
				
				else if (route.path == "/login" || route.path == "/register") {
					await $event.saveEvent("user.login", { uid: user.uid }, false);
					store.state.main.User = user;
					router.replace("/");
				} else { store.dispatch("main/setUserLogged", user); }
			});
		});
	},
};
</script>
'use strict';
const $_firebase = require("./firebase");

const ui = { 

    getMainMenu: async function(opt =  {}) { 
        const snapshot          = await $_firebase.firestore().collection('view').doc('layout').get();
        let item                = snapshot.data(); item.id = snapshot.id
        if (!item.main_menu) return []
        let mainMenu            = JSON.parse(item.main_menu)

        if(opt.raw)return JSON.parse(JSON.stringify(mainMenu));
        else return mainMenu;
    },

    getConfig: async function(type) {
        const snapshot  = await $_firebase.firestore().collection('view').doc(type).get();
        let item        = snapshot.data();
        if (item){
            item.id  = snapshot.id
            return item;
        } else return {}
    }, 
}

module.exports = ui
import { helper as $h } from "@/utils/helper";
import { settings as $settings } from "@/model/settings";
import * as rosepetalModel from "rosepetal-model";

var $_firebase = rosepetalModel._firebase;

const users = {

    async getUserData(uid) {
        let userData = await $_firebase.firestore().collection('users').doc(uid).get();
        return userData.data() ? userData.data() : false;
    },

    async getapiUrl() {
        let Settings = await $settings.get('api');
        let ApiUrl   = Settings.url_local;
        let apipath  = 'user/';
        return ApiUrl + apipath;
    },

    async list() {
       let users = []; 
       let functions = rosepetalModel._firebase.firebase ? rosepetalModel._firebase.firebase.app().functions('europe-west1') : null;
       if (!functions) return users;
       const action = functions.httpsCallable('api/user/list');
       await action({}).then(response => { if (response.data) users = response.data; }).catch(async (error) => { console.log(error); });
       if (users.length) {
            for (let i = 0; i < users.length; i++) {
                let user = users[i];
                user.data = await this.getUserData(user.uid);
                users[i] = user;
            }
       }
       return users;
    },

    async getRole(email) { 
        let userRole = { role_id: 1, name: 'user' };

        await $_firebase.firestore().collection('view').doc('users').get().then((doc) => {
            if (doc.exists) {
                let roles = doc.data().roles;
                if (roles) {
                    Object.keys(roles).forEach((role) => {
                        if (roles[role].includes(email)) {
                            if (role === 'admin')           userRole = { role_id: 2, name: role };
                            else if (role === 'superadmin') userRole = { role_id: 3, name: role };
                        }
                    });
                }
            }
        });

        return userRole;

        /*
        
        let role = { role_id: 1, name: 'user' };
        let functions = rosepetalModel._firebase.firebase ? rosepetalModel._firebase.firebase.app().functions('europe-west1') : null;
        if (!functions) { console.log("No functions"); return role; }
        const action = functions.httpsCallable('api/user/role/email/' + email);
        await action().then(response => { if (response.data) role = response.data; }).catch(async (error) => { if (error) console.log("getRole:", error); });
        return role; 
        
        */
    },

    async delete(uid, msg = false) {
        let functions = rosepetalModel._firebase.firebase ? rosepetalModel._firebase.firebase.app().functions('europe-west1') : null;
        const action = functions.httpsCallable('api/user/delete');
        await action({ uid: uid }).then(() => { if (msg) $h.NotificationTxt({ text: 'Usuario eliminado correctamente' }); })
                                  .catch((error) => { if (msg) $h.NotificationTxt({ text: 'No se ha podido eliminar el usuario. ' + error }); });
    },

    async update(user, msg = false) {
        let functions = rosepetalModel._firebase.firebase ? rosepetalModel._firebase.firebase.app().functions('europe-west1') : null;
        const action = functions.httpsCallable('api/user/update');
        if (user.data) delete(user.data);
        await action(user).then(() => { if (msg) $h.NotificationTxt({ text: 'Usuario actualizado correctamente' }); })
                          .catch((error) => { if (msg) $h.NotificationTxt({ text: 'No se ha podido actualizar el usuario. ' + error }); });
    },

    async create(user, msg = false) {
        let functions = rosepetalModel._firebase.firebase ? rosepetalModel._firebase.firebase.app().functions('europe-west1') : null;
        const action = functions.httpsCallable('api/user/create');
        await action(user).then(() => { if (msg) $h.NotificationTxt({ text: 'Usuario creado correctamente' }); })
                          .catch((error) => { if (msg) $h.NotificationTxt({ text: 'No se ha podido crear el usuario. ' + error }); });
    },

    async search(uid) {
        let user = [];
        let functions = rosepetalModel._firebase.firebase ? rosepetalModel._firebase.firebase.app().functions('europe-west1') : null;
        if (!functions) return user;
        const action = functions.httpsCallable('api/user/search/uid/' + uid);
        await action({}).then(response => { if (response.data) user = response.data; }).catch(async (error) => { console.log(error); });
        return user;
     },
};

const install = app => {
    app.config.globalProperties.$users = users;
};

export { install as default, users as user };
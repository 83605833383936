<template>
	<div class="w-full text-gray-900 labelingBox" v-if="layout.dataset" style="min-width: 1000px; z-index: 9999999;height: 100%;background-color: #fff;">
		<button type="button" data-dismiss="modal" :style="'border-radius: 0 !important'" class="btn text-gray-900 hover:bg-blue-500 hover:text-white flex items-center text-sm py-3 text-base float-right" @click="hideTools()"><Log-outIcon class="w-8 h-5" /></button>
		
		<div class="grid grid-cols-12 p-5 ">

			<div class="col-span-12">
				<div class="text-xs">Dataset</div>
				<div class="text-lg font-medium">{{  layout.dataset.id }}</div>
			</div>

			<div class="col-span-12">
				<table class="table table--sm text-xs text-left mt-5">
					<tbody>

						<!-- updatedAt -->
						<tr>
							<td class="border"><span class="font-medium text-sm text-blue-500">updatedAt</span></td>
							<td class="border text-xs">api</td>
							<td class="border text-xs"><span>Updates the "UpdatedAt" field of all images in the dataset.<br />Required for image mosaic to work for old datasets.</span></td>
							<td class="border">
								<div v-if="!layout.action.status || layout.action.status=='success'" class="py-1 rounded-md text-center bg-blue-500 text-white cursor-pointer border zoom-in w-32" @click="action('updatedAt')">{{$t('Update')}}</div>
								<div v-else-if="layout.action.type=='updatedAt'"><div class="py-2 w-32 text-sm text-green-500">processing...</div></div>
							</td>
						</tr>

						<!-- refreshCounters -->
						<tr>
							<td class="border"><span class="font-medium text-sm text-blue-500">refreshCounters</span></td>
							<td class="border text-xs">Usapi</td>
							<td class="border text-xs">
								<span>Updates the counters for each tag in the dataset.</span>
								<div>imageCounter and annotationsCounter / trainCounter, testCounter, validationCounter and predeterminedCounter</div>
							</td>
							<td class="border">
								<div v-if="!layout.action.status || layout.action.status=='success'" class="py-1 rounded-md text-center bg-blue-500 text-white cursor-pointer border zoom-in w-32" @click="action('refreshCounters')">{{$t('Update')}}</div>
								<div v-else-if="layout.action.type=='refreshCounters'"><div class="py-2 w-32 text-sm text-green-500">processing...</div></div>
							</td>
						</tr>

						<!-- generateCSV -->
						<tr>
							<td class="border"><span class="font-medium text-sm text-blue-500">Generate CSV</span></td>
							<td class="border text-xs">api</td>
							<td class="border text-xs">
								<span>Generates a CSV file with the dataset's images and annotations.</span>
								<div>It will be stored in firebase storage</div>
							</td>
							<td class="border">
								<template v-if="!layout.action.status || layout.action.status=='success'">
									<div class="py-1 rounded-md text-center bg-blue-500 text-white cursor-pointer border zoom-in w-32" @click="action('generateCSV_manual')">{{$t('Manual')}}</div>
									<div class="py-1 rounded-md text-center bg-blue-500 text-white cursor-pointer border zoom-in w-32 mt-1" @click="action('generateCSV')">{{$t('Auto')}}</div>
									<div class="mt-1">
										<div>Test: <input type="text" class="w-12 border rounded-md p-1 text-xs ml-8" v-model="layout.csvDivision.validation" /></div>
										<div class="mt-2">Validation: <input type="text" class="w-12 border rounded-md p-1 text-xs ml-1" v-model="layout.csvDivision.validation" /></div>
									</div>
								</template>
								<div v-else-if="layout.action.type=='generateCSV' || layout.action.type=='generateCSV_manual'"><div class="py-2 w-32 text-sm text-green-500">processing...</div></div>
							</td>
						</tr>

						<!-- downloadCSV -->
						<tr>
							<td class="border"><span class="font-medium text-sm text-blue-500">Download CSV</span></td>
							<td class="border text-xs">api</td>
							<td class="border text-xs">
								<span>Download the latest CSV file generated for the dataset.</span>
								<div>Name: {{ props.options.dataset }}.csv</div>
							</td>
							<td class="border">
								<template v-if="!layout.action.status || layout.action.status=='success'">
									<div class="py-1 rounded-md text-center bg-blue-500 text-white cursor-pointer border zoom-in w-32" @click="action('downloadCSV')">{{$t('Download')}}</div>
								</template>
								<div v-else-if="layout.action.type=='downloadCSV'"><div class="py-2 w-32 text-sm text-green-500">processing...</div></div>
							</td>
						</tr>

					</tbody>
				</table>
			</div>
		</div>

		<div class="grid grid-cols-12 mt-5">
			<div class="col-span-12 text-sm bg-black text-white p-5 pt-3 pb-3">
				Console 
				<span v-if="layout.action"><span class="text-yellow-500 ml-8">{{ layout.action.type }}</span></span>
				<span v-else><span class="text-yellow-500 ml-8">get</span></span>
			</div>
		</div>

		<div class="grid grid-cols-12 h-full">
			<div class="col-span-12 overflow-y-auto h-full bg-black text-white px-5 pb-96 align-top" style="-ms-overflow-style: none; scrollbar-width: none; overflow-y: scroll;">
				<pre v-if="layout.action.resp" class="text-xs p-5">{{ layout.action.resp }}</pre>
				<div v-else-if="layout.action.status" class="text-sm p-5 text-green-500 text-left">
					Start: {{ layout.action.startAt }}<br />{{ layout.action.status }}
				</div>
				<pre v-else class="text-xs p-5">{{ layout.dataset }}</pre>
			</div>
		</div>

	</div>
</template>

<script>
import { defineComponent, onMounted, ref, onUnmounted } from "vue"; 
import { useRouter } 		from "vue-router";
import cash 				from "cash-dom";

import * as rosepetalModel	from "rosepetal-model";
import config 				from '@/etc/rosepetal.json';

export default defineComponent({
	props: {
		options: {
			type: Object,
			required: true
		},
	},

	created () {
		this.helper = rosepetalModel.helper
	},

	setup(props) {

		const router = useRouter();
		const layout = ref({
			dataset: false,
			action: false,
			csvDivision: {
				method: "auto",
				test: 10,
				validation: 10
			}
		});
	
		const load = async () => { 
			layout.value.dataset = await rosepetalModel.dataset.get(props.options.dataset)
			layout.value.dataset.tagsCounter = await rosepetalModel.dataset.getTagsCounter(props.options.dataset, true)
			layout.value.dataset.dataDivision = await rosepetalModel.dataset.getDataDivision(props.options.dataset);
		}

		const action = async (type) => { 
			layout.value.action = { type: type, status: 'processing...', startAt: new Date() }
			if(type=='updatedAt'){
				layout.value.action.resp = await rosepetalModel.dataset.setImagesUpdatedDate(props.options.dataset)
				layout.value.action.status = "success"
			}else if(type=='refreshCounters'){
				rosepetalModel.dataset.refreshCounters(props.options.dataset, config.functions.usapi).then(() => { layout.value.action.resp = { status: "success"}; layout.value.action.status = "success" } )
			}
			
			else if(type=='generateCSV'){
				layout.value.action.resp = await rosepetalModel.dataset.getVertexCsv(props.options.dataset, layout.value.csvDivision).then(() => { layout.value.action.resp = { status: "success"}; layout.value.action.status = "success" } )
			}
			
			else if(type=='generateCSV_manual'){
				layout.value.action.resp = await rosepetalModel.dataset.getVertexCsv(props.options.dataset).then(() => { layout.value.action.resp = { status: "success"}; layout.value.action.status = "success" } )
			}
			
			else if(type=='downloadCSV'){
				layout.value.action.resp = await rosepetalModel.dataset.downloadVertexCsv(props.options.dataset, config.firebaseConfig.projectId).then((resp) => { 
					layout.value.action.resp = { status: "success" , csv: resp.csv }; 
					layout.value.action.status = "success"
					if(resp.status=="success" && resp.csv)window.open(resp.csv, '_blank');
				} )
			}
		}

		onMounted( async () => {
			router.push('/datasets/'+props.options.dataset+'/tools'); 
			cash("#rpTopBarLine").hide()
			if(props.options.dataset)await load()
			cash("body").css("overflow-y", "hidden");
		});

		onUnmounted( async () => { 
			cash("body").css("overflow-y", "scroll");  
			cash(".wrapper-box .content").removeClass("singlemode_wrapper"); 
			cash("#rpTopBarLine").show() 
		});

		return {
			layout,
			router,
			props,
			action,
			load,
		};
	},

	methods: {
		hideTools: async function () { this.router.push('/datasets/'+this.props.options.dataset); this.$parent.hideTools(); },
	}
});
</script>